













import { Vue, Component, Prop } from 'vue-property-decorator'
import BotaoDeDrawer from '@/components/ui/BotaoDeDrawer.vue'
import IconesDaToolbarPadroes from '@/components/layout/IconesDaToolbarPadroes.vue'

@Component({
	components: {
		BotaoDeDrawer,		
		IconesDaToolbarPadroes,
	},
})
export default class ToolbarDoDashboardInicial extends Vue {
	@Prop({ type: String, default: '' }) titulo!: string
}
